import { useEffect, useState } from 'react';
import i18n from '../../common/languageConf';
import '../../css/style.css';
import { observer } from "mobx-react";
import GlobalSettingStore from '../../Stores/GlobalSettingStore';
import ProjectsHeader from './ProjectsHeader';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import { Link } from 'react-router-dom';
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from 'yet-another-react-lightbox';
import { pagesizecontrol } from '../../common/pagesizecontrol';
import PhotoComponent from '../PhotoComponent';
import Lightboxcomponent from '../Lightboxcomponent';

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id: string, width: number, height: number) =>
	`../img/tamamlananprojelernew/${id}`;

const unsplashPhotos = [
	{ id: "Picture68.jpg", width: 1080, height: 800 },
	{ id: "Picture69.jpg", width: 1080, height: 800 },
	{ id: "Picture70.jpg", width: 1080, height: 800 },

];

const photos = unsplashPhotos.map((photo) => {
	const width = breakpoints[0];
	const height = (photo.height / photo.width) * width;

	return {
		src: unsplashLink(photo.id, width, height),
		width,
		height,
		srcSet: breakpoints.map((breakpoint) => {
			const height = Math.round((photo.height / photo.width) * breakpoint);
			return {
				src: unsplashLink(photo.id, breakpoint, height),
				width: breakpoint,
				height,
			};
		}),
	};
});

function Asitdepolama1() {
	const [index, setIndex] = useState(-1);
	useEffect(() => {

	}, [GlobalSettingStore.GlobalSetting])
	window.scrollTo(0, 0);

	return (
		<>
			<ProjectsHeader />
			<br />
			<div className="container">
				<Link to="/projects">
					<i style={{ marginTop: 30, marginBottom: 30, width: "100px", height: "100px" }} className="fa fa-arrow-left" aria-hidden="true" />
				</Link>
				<div className="row">
					<div className="col-md-12">
						<div className="text" style={{ whiteSpace: "pre-line" }}>
							<p>{i18n.translate("asitbaslik1")}</p>
							<p>{i18n.translate("asiticerik1")}</p>
						</div>
						<div style={{ marginTop: 75, marginBottom: 50, }}></div>
						<PhotoComponent photos={photos} setIndex={setIndex} pagesizecontrol={pagesizecontrol()} />
						<Lightboxcomponent slides={photos} open={index >= 0} index={index} close={() => setIndex(-1)} plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]} photos={photos} Fullscreen={Fullscreen} Slideshow={Slideshow} Thumbnails={Thumbnails} Zoom={Zoom} setIndex={setIndex} />
						
						<br />
						<br />
						<br />
						<br />
						<div className="questions-sol">

						</div>
					</div>

				</div>
			</div>
		</>
	);
}
const EnergyComponent = observer(Asitdepolama1);
export default EnergyComponent;

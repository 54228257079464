import i18n from "../common/languageConf";
const Sidebar = () => {
	return (
		<aside className="blog-info">
			<div className="search-box">
				<form>
					<div className="form-group">

						<input className="form-control" placeholder="Search..." type="search" />
						<button type="submit">
							<i className="fa fa-search" aria-hidden="true"></i>
						</button>

					</div>
				</form>
			</div>
			<div className="category-widget">
				<div className="title">
					<h4>Categories</h4>
				</div>
				<ul>
					<li>
						<a href="">Manufactoring</a>
					</li>
					<li>
						<a href="">Chemical</a>
					</li>
					<li>
						<a href="">Construction</a>
					</li>
					<li>
						<a href="">Energy</a>
					</li>
					<li>
						<a href="">Materials</a>
					</li>
				</ul>
			</div>
			<div className="news-widget">
				<div className="title">
					<h4>Popular Products</h4>
				</div>
				<div className="news-item">
					<figure>
						<img src="img/shop-thumbnail.jpg" alt="" />
					</figure>
					<div className="text-box">
						<h5><a href=""> Portable Drill 2000W</a></h5>
						<ul>
							<li><i className="fa fa-star" aria-hidden="true"></i></li>
							<li><i className="fa fa-star" aria-hidden="true"></i></li>
							<li><i className="fa fa-star" aria-hidden="true"></i></li>
							<li><i className="fa fa-star" aria-hidden="true"></i></li>
							<li><i className="fa fa-star" aria-hidden="true"></i></li>
						</ul>
						<p>
							$39.00
						</p>
					</div>
				</div>
				<div className="news-item">
					<figure>
						<img src="img/shop-thumbnail2.jpg" alt="" />
					</figure>
					<div className="text-box">
						<h5><a href=""> Portable Drill 2000W </a></h5>
						<p>
							$39.00
						</p>
					</div>
				</div>
				<div className="news-item">
					<figure>
						<img src="img/shop-thumbnail3.jpg" alt="" />
					</figure>
					<div className="text-box">
						<h5><a href=""> Portable Drill 2000W </a></h5>
						<p>
							$39.00
						</p>
					</div>
				</div>
			</div>
			<div className="instigram-widget">
				<div className="title">
					<h4>Filter By Price</h4>
				</div>
				<div id="slider-range"></div>
				<form method="post" action="get_items.php">
					<input type="hidden" id="amount1" />
					<input type="hidden" id="amount2" />
					<input type="submit" name="submit_range" value="Submit" />
				</form>

			</div>
			<div className="tags-widget">
				<div className="title">
					<h4>Popular Tags</h4>
				</div>
				<ul>
					<li>
						<a href=""> Future</a>
					</li>
					<li>
						<a href="">Science</a>
					</li>
					<li>
						<a href="">Factory</a>
					</li>
					<li>
						<a href=""> Industrial</a>
					</li>
					<li>
						<a href="">Construction</a>
					</li>
					<li>
						<a href="">Glass</a>
					</li>
					<li>
						<a href="">Helmet</a>
					</li>
					<li>
						<a href="">Wind Energy</a>
					</li>
					<li>
						<a href="">Gloves</a>
					</li>
				</ul>

			</div>
		</aside>
	);
};
function ShopsPage() {
	return (
		<>


			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>News</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>

							<li>
								Home
							</li>
							<li>
								<i className="fa fa-angle-right" aria-hidden="true"></i>
							</li>
							<li>
								<span>News</span>
							</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="shop-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="product-wraper">

								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop1.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop2.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Deep Cut Band Saw - DWM120</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>

								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop3.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop1.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop2.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Deep Cut Band Saw - DWM120</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop3.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop1.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop2.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Deep Cut Band Saw - DWM120</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="product">
										<div className="img-box"><img src="img/shop3.jpg" alt="img" /> </div>
										<div className="text-box">
											<h4>Gutter Supply</h4>
											<ul>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
												<li><i className="fa fa-star" aria-hidden="true"></i></li>
											</ul>
											<p>$39.00</p>
											<a className="btn hvr-bounce-to-right" href="#">ADD TO CARD</a>
										</div>
									</div>
								</div>

							</div>
							<div className="navigation">
								<ul>
									<li><a href="">1</a> </li>
									<li><a href="">2</a> </li>
									<li><a href=""><i className="fa fa-angle-right" aria-hidden="true"></i></a> </li>
								</ul>
							</div>
						</div>
						<div className="col-md-3">
							<Sidebar />
						</div>
					</div>
				</div>
			</section>



		</>
	);
}

export default ShopsPage;
import { useEffect, useState } from 'react';
import i18n from '../../common/languageConf';
import { observer } from "mobx-react";
import GlobalSettingStore from '../../Stores/GlobalSettingStore';
import { Link } from 'react-router-dom';
import PhotoAlbum, { Photo, RenderPhotoProps } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import React from 'react';
import { pagesizecontrol } from '../../common/pagesizecontrol';
import PhotoComponent from '../PhotoComponent';
import CompaniesPhoto from './CompaniesPhoto';

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id: string, width: number, height: number) =>
    `../img/nerinimg/${id}`;

const unsplashPhotos = [
    { id: "nerin1.png", width: 1080, height: 800 },
    { id: "nerin2.png", width: 1080, height: 800 },
    { id: "nerin3.png", width: 1080, height: 800 },
    { id: "nerin4.png", width: 1080, height: 800 },
    { id: "nerin5.png", width: 1080, height: 800 },
    { id: "nerin6.png", width: 1080, height: 800 },
    { id: "nerin7.png", width: 1080, height: 800 },
    { id: "nerin8.png", width: 1080, height: 800 },
    { id: "nerin9.png", width: 1080, height: 800 },
    { id: "nerin10.png", width: 1080, height: 800 },
    { id: "nerin11.png", width: 1080, height: 800 },
    { id: "nerin12.png", width: 1080, height: 800 },
    { id: "nerin13.png", width: 1080, height: 800 },
    { id: "nerin14.png", width: 1080, height: 800 },
    { id: "nerin15.png", width: 1080, height: 800 },
    { id: "nerin16.png", width: 1080, height: 800 },
    { id: "nerin17.png", width: 1080, height: 800 },
    { id: "nerin18.png", width: 1080, height: 800 },
    { id: "nerin19.png", width: 1080, height: 800 },
    { id: "nerin20.png", width: 1080, height: 800 },
    { id: "nerin21.png", width: 1080, height: 800 },
    { id: "nerin22.png", width: 1080, height: 800 },
    { id: "nerin23.png", width: 1080, height: 800 },
    { id: "nerin24.png", width: 1080, height: 800 },
    { id: "nerin25.png", width: 1080, height: 800 },
    { id: "nerin26.png", width: 1080, height: 800 },
    { id: "nerin27.png", width: 1080, height: 800 },
    { id: "nerin28.png", width: 1080, height: 800 },
    { id: "nerin29.png", width: 1080, height: 800 },
    { id: "nerin30.png", width: 1080, height: 800 },
    { id: "nerin31.png", width: 1080, height: 800 },
    { id: "nerin32.png", width: 1080, height: 800 },
    { id: "nerin33.png", width: 1080, height: 800 },
    { id: "nerin34.png", width: 1080, height: 800 },
    { id: "nerin35.png", width: 1080, height: 800 },
    { id: "nerin36.png", width: 1080, height: 800 },
    { id: "nerin37.png", width: 1080, height: 800 },
    { id: "nerin38.png", width: 1080, height: 800 },
    { id: "nerin39.png", width: 1080, height: 800 },
    { id: "nerin40.png", width: 1080, height: 800 },
    { id: "nerin41.png", width: 1080, height: 800 },
    { id: "nerin42.png", width: 1080, height: 800 },
    { id: "nerin43.png", width: 1080, height: 800 },
    { id: "nerin44.png", width: 1080, height: 800 },
    { id: "nerin45.png", width: 1080, height: 800 },
    { id: "nerin46.png", width: 1080, height: 800 },
    { id: "nerin47.png", width: 1080, height: 800 },
    { id: "nerin48.png", width: 1080, height: 800 },
    { id: "nerin49.png", width: 1080, height: 800 },
    { id: "nerin50.png", width: 1080, height: 800 },
    { id: "nerin51.png", width: 1080, height: 800 },
    { id: "nerin52.png", width: 1080, height: 800 },
    { id: "nerin53.png", width: 1080, height: 800 },
    { id: "nerin54.png", width: 1080, height: 800 },
    { id: "nerin55.png", width: 1080, height: 800 },
    { id: "nerin56.png", width: 1080, height: 800 },
    { id: "nerin57.png", width: 1080, height: 800 },
    { id: "nerin58.png", width: 1080, height: 800 },
    { id: "nerin59.png", width: 1080, height: 800 },
    { id: "nerin60.png", width: 1080, height: 800 },
    { id: "nerin61.png", width: 1080, height: 800 },
    { id: "nerin62.png", width: 1080, height: 800 },
    { id: "nerin63.png", width: 1080, height: 800 },
    { id: "nerin64.png", width: 1080, height: 800 },
    { id: "nerin65.png", width: 1080, height: 800 },
    { id: "nerin66.png", width: 1080, height: 800 },
    { id: "nerin67.png", width: 1080, height: 800 },
    { id: "nerin68.png", width: 1080, height: 800 },
    { id: "nerin69.png", width: 1080, height: 800 },
    { id: "nerin70.png", width: 1080, height: 800 },
    { id: "nerin71.png", width: 1080, height: 800 },
    { id: "nerin72.png", width: 1080, height: 800 },
    { id: "nerin73.png", width: 1080, height: 800 },
    { id: "nerin74.png", width: 1080, height: 800 },
    { id: "nerin75.png", width: 1080, height: 800 },
    { id: "nerin76.png", width: 1080, height: 800 },
    { id: "nerin77.png", width: 1080, height: 800 },
    { id: "nerin78.png", width: 1080, height: 800 },
    { id: "nerin79.png", width: 1080, height: 800 },
    { id: "nerin80.png", width: 1080, height: 800 },
    { id: "nerin81.png", width: 1080, height: 800 },
    { id: "nerin82.png", width: 1080, height: 800 },
    { id: "nerin83.png", width: 1080, height: 800 },
    { id: "nerin84.png", width: 1080, height: 800 },
    { id: "nerin85.png", width: 1080, height: 800 },
    { id: "nerin86.png", width: 1080, height: 800 },
    { id: "nerin87.png", width: 1080, height: 800 },
    { id: "nerin88.png", width: 1080, height: 800 },
    { id: "nerin89.png", width: 1080, height: 800 },
    { id: "nerin90.png", width: 1080, height: 800 },
    { id: "nerin91.png", width: 1080, height: 800 },
    { id: "nerin92.png", width: 1080, height: 800 },
    { id: "nerin93.png", width: 1080, height: 800 },
    { id: "nerin94.png", width: 1080, height: 800 },
    { id: "nerin95.png", width: 1080, height: 800 },
    { id: "nerin96.png", width: 1080, height: 800 },
    { id: "nerin97.png", width: 1080, height: 800 },
    { id: "nerin98.png", width: 1080, height: 800 },
    { id: "nerin99.png", width: 1080, height: 800 },
    { id: "nerin100.png", width: 1080, height: 800 },
    { id: "nerin101.png", width: 1080, height: 800 },
    { id: "nerin102.png", width: 1080, height: 800 },
    { id: "nerin103.png", width: 1080, height: 800 },
    { id: "nerin104.png", width: 1080, height: 800 },
    { id: "nerin105.png", width: 1080, height: 800 },
    { id: "nerin106.png", width: 1080, height: 800 },
    { id: "nerin107.png", width: 1080, height: 800 },
    { id: "nerin108.png", width: 1080, height: 800 },
    { id: "nerin109.png", width: 1080, height: 800 },
    { id: "nerin110.png", width: 1080, height: 800 },







];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: unsplashLink(photo.id, width, height),
        width,
        height,
        srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: unsplashLink(photo.id, breakpoint, height),
                width: breakpoint,
                height,
            };
        }),
    };
});


function Nerin() {
    const [index, setIndex] = useState(-1);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [GlobalSettingStore.GlobalSetting])



    return (
        <>


            <div className="container">
                <Link to="/companies">
                    <i style={{ marginTop: 30, width: "100px", height: "50px" }} className="fa fa-arrow-left" aria-hidden="true" />
                </Link>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ fontSize: "30px", whiteSpace: "pre-line" }}>
                            <p>China Nerin Engineering Co., Ltd</p>
                            <a href="http://www.nerin.com/" target='_blank' style={{ textDecoration:"underline", fontSize: "25px", color:"blue" }}>www.nerin.com</a>
                        </div>
                        <div style={{ marginTop: 75, marginBottom: 50, }}></div>
                        <div className='phoyo-container'>
                            <CompaniesPhoto photos={photos} setIndex={setIndex} pagesizecontrol={pagesizecontrol()} 
                            />
                        </div>

                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />

                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="questions-sol">

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
const NerinComponent = observer(Nerin);
export default NerinComponent;

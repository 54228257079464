import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PhotoAlbum, { RenderPhotoProps } from 'react-photo-album'

type AlbumProps = {
    photos: any,
    setIndex: any
    pagesizecontrol:boolean
    
}

export default function CompaniesPhoto({ photos, setIndex, pagesizecontrol }: AlbumProps) {

    console.log(pagesizecontrol)
      // Fotoğraf boyutlarını ayarlamak için değişken stil
  const photoStyle = pagesizecontrol
  ? { width: '400px', height: '250px' } // Küçük boyut (mobil)
  : { width: "80%", height: "80%" }; // Normal boyut
    return (
        <div className="photo-container">
            {photos.map((photo: { src: string | undefined }, index: React.Key | null | undefined) => (
                <img
                    key={index}
                    src={photo.src}
                    alt={`Photo ${index}`}
                    style={{...photoStyle ,marginBottom: '20px', cursor: 'pointer' }}
                    onClick={() => setIndex(index)}
                />
            ))}
        </div>
    )

}

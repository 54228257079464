import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PhotoAlbum, { RenderPhotoProps } from 'react-photo-album'

type AlbumProps = {
    photos: any,
    setIndex: any
    pagesizecontrol:boolean
    
}

export default function PhotoComponent({ photos, setIndex, pagesizecontrol }: AlbumProps) {

    console.log(pagesizecontrol)

    const renderPhoto = React.useCallback(
        ({ imageProps: { alt, style, ...rest } }: RenderPhotoProps) => (
            <img
                alt={alt}
                style={{
                    ...style,
                    borderRadius: 5 > 2 ? "4px" : 0,
                    boxShadow:
                        5 > 0
                            ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                            : "none",
                    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
                {...rest}
            />
        ),
        [5, 5],
    );
    return (
        <PhotoAlbum
            columns={pagesizecontrol ? 1 : photos.length}
            photos={photos}
            layout={"columns"}
            spacing={5}
            padding={5}
            targetRowHeight={50}
            renderPhoto={renderPhoto}
            onClick={({ index }) => setIndex(index)} />
    )

}

import { useEffect, useState } from 'react';
import i18n from '../../common/languageConf';
import '../../css/style.css';
import { observer } from "mobx-react";
import GlobalSettingStore from '../../Stores/GlobalSettingStore';
import ProjectsHeader from './ProjectsHeader';
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import { Link } from 'react-router-dom';
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import PhotoAlbum, { RenderPhotoProps } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import React from 'react';
import { pagesizecontrol } from '../../common/pagesizecontrol';

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id: string, width: number, height: number) =>
  `../img/tamamlananprojelernew/${id}`;

const unsplashPhotos = [
  { id: "Picture7.jpg", width: 200, height: 100 },

];

const photos = unsplashPhotos.map((photo) => {
  const width = breakpoints[0];
  const height = (photo.height / photo.width) * width;

  return {
    src: unsplashLink(photo.id, width, height),
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const height = 100 //Math.round((photo.height / photo.width) * breakpoint);
      return {
        src: unsplashLink(photo.id, breakpoint, height),
        width: 100,//breakpoint,
        height,
      };
    }),
  };
});

function Enerji3() {
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(-1);
	useEffect(() => {

	}, [GlobalSettingStore.GlobalSetting])
	window.scrollTo(0, 0);

	const buttonStyle = {
		padding: "15px",
		borderRadius: "50%",
		background: "red",
		opacity: 0.7,
		fontSize: "20px"
	};
	const contentStyle = {
		color: "black",
		fontSize: "20px"
	};
	const bgImg = {
		position: "absolute",
		zIndex: -1,
		left: 0,
		top: 0,
		width: "100%"
	};
	const renderPhoto = React.useCallback(
		({ imageProps: { alt, style, ...rest } }: RenderPhotoProps) => (
		  <img
			alt={alt}
			style={{
			  ...style,
			  borderRadius: 5 > 2 ? "4px" : 0,
			  boxShadow:
				5 > 0
				  ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
				  : "none",
			  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			}}
			{...rest}
		  />
		),
		[5, 5],
	  );

	return (
		<>
			<ProjectsHeader />
			<br />
			<div className="container">
				<Link to="/projects">
					<i style={{ marginTop: 30, marginBottom: 30, width: "100px", height: "100px" }} className="fa fa-arrow-left" aria-hidden="true" />
				</Link>
				<div className="row">
					<div className="col-md-12">
						<div className="text" style={{ whiteSpace: "pre-line" }}>
							<p>{i18n.translate("enerjibaslik3")}</p>
							<p>{i18n.translate("enerjiicerik3")}</p>
						</div>
						<div style={{ marginTop: 75, marginBottom: 50, }}></div>
						<div style={{width:500, height:400}}>
						<PhotoAlbum //@ts-ignore
								columns={pagesizecontrol ? photos.length : 1} 
								photos={photos}
								layout={"columns"}
								spacing={5}
								padding={5}
								targetRowHeight={50}
								renderPhoto={renderPhoto}
								onClick={({ index }) => setIndex(index)} />						</div>
						<Lightbox
							slides={photos}
							open={index >= 0}
							index={index}
							close={() => setIndex(-1)}
							// enable optional lightbox plugins
							plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
						/>


						{/* <AwesomeSlider
							style={{ objectFit: "scale-down", width: "80%", height: "80%", marginnTop: 75, marginbottom: 50 }}
							organicArrows={false}
							buttonContentRight={<p style={buttonStyle}>{">"}</p>}
							buttonContentLeft={<p style={buttonStyle}>{"<"}</p>} animation="cubeAnimation"
							cssModule={{ objectFit: "cover", width: "80%", height: "80%", alignSelf: "center", alignItems: "center", justifyContent: "center" }}

						>
							<div data-src="../img/tamamlananprojelernew/Picture7.jpg" />
						</AwesomeSlider> */}
						<br />
						<br />
						<br />
						<br />
						<div className="questions-sol">

						</div>
					</div>

				</div>
			</div>
		</>
	);
}
const EnergyComponent = observer(Enerji3);
export default EnergyComponent;

import { useEffect, useState } from 'react';
import i18n from '../common/languageConf';
import '../css/style.css';
import { useLanguage } from '../common/LanguageContext';
import { observer } from "mobx-react";
import GlobalSettingStore from '../Stores/GlobalSettingStore';
import emailjs from '@emailjs/browser';
import { selectoptions } from '../common/selectoptions';



function Contact() {
	const { language, changeLanguage } = useLanguage();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMesaage] = useState("");
	const [industry, setIndustry] = useState("");



	useEffect(() => {
		changeLanguage("tr")
	}, [GlobalSettingStore.GlobalSetting])

	function mailSender(e: any) {
		e.preventDefault();
		console.log("mailsender")
		emailjs.send("service_8fdhhye", "template_rmhs4tw", {
			from_name: name,
			message: message,
			reply_to: "info@epcproje.com",
			email: email,
			phone: phone,
			industry: industry,
		}, "5pC6zbBlEAFWRtcwS").then((response) => {
			console.log('SUCCESS!', response.status, response.text);
			alert(i18n.t("sendedMail"))
			setEmail("")
			setName("")
			setIndustry("")
			setMesaage("")
			setPhone("")
		}, (err) => {
			console.log('FAILED...', err);
		});
	}
	return (
		<>
			<section className="contact">
				<div className="container">
					<div className="row">
						<div className="contact-wraper">
							<div className="col-md-6 nopd">
								<div className="contact-info">
									<h2>{i18n.t("Contactustoday")}</h2>
									<p>
										{i18n.t("Formoreinformation")}
									</p>
								</div>
							</div>
							<div className="col-md-6">
								<form id="request-call-form" onSubmit={mailSender}  >
									<div className="col-md-6">

										<input id="name" type="text" required placeholder={i18n.t("Name")} value={name} onChange={(e) => { setName(e.target.value) }} name="name" />

									</div>
									<div className="col-md-6">

										<input id="email" type="email" required placeholder={i18n.t("Email")} value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" />

									</div>
									<div className="col-md-6">

										<input id="phone" type="number" onChange={(e) => { setPhone(e.target.value) }} value={phone} required placeholder={i18n.t("Phone")} name="phone" />

									</div>
									<div className="col-md-6">


										<select className="form-control select" value={industry} onChange={(e) => { setIndustry(e.target.value) }}>
											{
												selectoptions()
											}
										</select>



									</div>
									<div className="col-md-12">

										<textarea className="form-control" value={message} required cols={40} rows={5} onChange={(e) => { setMesaage(e.target.value) }} placeholder={i18n.t("Message")} id="inputComments"></textarea>





									</div>
									<button className="btn hvr-bounce-to-right" type='submit'>{i18n.t("SEND")}</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			</section >
		</>
	);
}
const ContactPageComponent = observer(Contact);
export default ContactPageComponent;

import { I18n } from "i18n-js";
import { tr } from "../lang/tr";
import { en } from "../lang/en";
import { de } from "../lang/de";
import { cn } from "../lang/cn";
import GlobalSettingStore from "../Stores/GlobalSettingStore";


let asynlang: string = "";
const i18n = new I18n({
  ...tr,
  ...en,
  ...de,
  ...cn,
});


async function initializeLocalization() {
  console.log("language conf > " + asynlang);

  const browserLanguage = navigator.language || navigator.language;
  const defaultLanguage = browserLanguage.startsWith('tr') ? 'tr' : browserLanguage.startsWith('cn') ? 'cn' : 'en';
  console.log("Browser Language > " + defaultLanguage);

  GlobalSettingStore.setGlobalSetting(defaultLanguage)

  i18n.enableFallback = true;
  i18n.translations = { en, de, tr, cn };
  i18n.locale = GlobalSettingStore.getGlobalSetting();

  console.log("language conf > " + asynlang + " locale " + i18n.locale);
  console.log("GlobalSettingStore.getGlobalSetting()" + GlobalSettingStore.getGlobalSetting());
  console.log("defaultLanguage" + GlobalSettingStore.getGlobalSetting());


}

initializeLocalization();

export default i18n;
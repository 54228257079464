import i18n from "./languageConf";

export function selectoptions() {
    return (
        <>
            <option>{i18n.t("Sektor")}</option>
            <option>{i18n.t("Enerji")}</option>
            <option>{i18n.t("Maden")}</option>
            <option>{i18n.t("Metallueloji")}</option>
            <option>{i18n.t("HavaAyristirma")}</option>
            <option>{i18n.t("Sulfurukasit")}</option>
            <option>{i18n.t("Muhendislik")}</option>
            <option>{i18n.t("ProjeYonetimi")}</option>
            <option>{i18n.t("Gubre")}</option>
            <option>{i18n.t("GazTemizleme")}</option>
            <option>{i18n.t("AltinLeach")}</option>
            <option>{i18n.t("Industrialplant")}</option>
        </>
    )
}
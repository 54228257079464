
import Lightbox from 'yet-another-react-lightbox'

type LightboxProps = {
    slides: any,
    open: any,
    index: any,
    close: any,
    plugins: any,
    photos: any,
    Fullscreen: any,
    Slideshow: any,
    Thumbnails: any,
    Zoom: any,
    setIndex: any,
}

export default function Lightboxcomponent({ slides, open, index, close, plugins, photos, Fullscreen, Slideshow, Thumbnails, Zoom, setIndex }: LightboxProps) {

    return (
        <Lightbox
            slides={photos}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        />
    )

}

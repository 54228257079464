import { useEffect, useState } from 'react';
import i18n from '../../common/languageConf';
import '../../css/style.css';
import { observer } from "mobx-react";
import GlobalSettingStore from '../../Stores/GlobalSettingStore';
import ProjectsHeader from './ProjectsHeader';
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import { Link } from 'react-router-dom';


function Mining() {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);

	}, [GlobalSettingStore.GlobalSetting])

	const buttonStyle = {
		padding: "15px",
		borderRadius: "50%",
		background: "red",
		opacity: 0.7,
		fontSize: "20px"
	};
	const contentStyle = {
		color: "black",
		fontSize: "20px"
	};
	const bgImg = {
		position: "absolute",
		zIndex: -1,
		left: 0,
		top: 0,
		width: "100%"
	};

	return (
		<>
			<ProjectsHeader />
			<br />
			<div className="container">
				<Link to="/projects">
					<i style={{ marginTop: 30, marginBottom: 30, width: "100px", height: "100px" }} className="fa fa-arrow-left" aria-hidden="true" />
				</Link>
				<div className="row">
					<div className="col-md-12">
						<div className="text" style={{ whiteSpace: "pre-line" }}>
							{i18n.translate("enerjitext")}

						</div>
						<div style={{ marginTop: 75, marginBottom: 50, }}></div>
						<AwesomeSlider
							style={{ marginnTop: 75, marginbottom: 50 }}
							organicArrows={false}
							buttonContentRight={<p style={buttonStyle}>{">"}</p>}
							buttonContentLeft={<p style={buttonStyle}>{"<"}</p>} animation="cubeAnimation"
							cssModule={{ objectFit: "cover", width: "80%", height: "80%", alignSelf: "center", alignItems: "center", justifyContent: "center" }}

						>
							<div data-src="../img/tamamlananprojeler/enerji_new.png" />
							<div data-src="../img/tamamlananprojeler/enerji2.jpg" />
							<div data-src="../img/tamamlananprojeler/enerji_new.png" />
						</AwesomeSlider>
						<br />
						<br />
						<br />
						<br />
						<div className="questions-sol">

						</div>
					</div>

				</div>
			</div>
		</>
	);
}
const EnergyComponent = observer(Mining);
export default EnergyComponent;

import { useEffect } from 'react';
import { useLanguage } from '../common/LanguageContext';
import i18n from '../common/languageConf';
import GlobalSettingStore from '../Stores/GlobalSettingStore';
import { Link } from 'react-router-dom';
import Contact from '../components/Contact';
import { observer } from "mobx-react";
import SolutionContent from '../components/solutionContent'
import { firstCharUpper } from '../common/textupper';

function HomePage() {

	const { language, changeLanguage } = useLanguage();


	useEffect(() => {
		changeLanguage("tr")
		window.scrollTo(0, 0);
		
	}, [GlobalSettingStore.GlobalSetting])
	return (
		<>

			<div id="minimal-bootstrap-carousel" data-ride="carousel" className="carousel slide carousel-fade shop-slider">
				<div role="listbox" className="carousel-inner">
					<div style={{ backgroundImage: "url(img/slide2.jpg)", backgroundPosition: "center" }} className="item active slide-1">
						<div className="carousel-caption">
							<div className="thm-container">
								<div className="box valign-bottom">
									<div className="content text-center">
										<h2 data-animation="animated fadeInUp"><span>{i18n.t("hometext1")}</span><br />{i18n.t("hometext2")} {i18n.t("hometext3")}</h2>
										{/* <p data-animation="animated fadeInDown">{i18n.t("Youwillbeal")} <br />{i18n.t("investmenttips")} </p> */}
										<div className="buttons">
											<a data-animation="animated fadeInLeft" href="about" className="thm-button hvr-bounce-to-right">{i18n.t("Learnmore")} </a>
											<a data-animation="animated fadeInRight" href="solution" className="thm-button inverse hvr-bounce-to-right2">{i18n.t("Viewservices")} </a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ backgroundImage: "url(img/slide3.jpg)", backgroundPosition: "center" }} className="item slide-2">
						<div className="carousel-caption">
							<div className="thm-container">
								<div className="box valign-bottom">
									<div className="content text-center">
										<h2 data-animation="animated fadeInUp"><span>{i18n.t("Leader")}</span> {i18n.t("inPower")}<br /></h2>
										{/* <p data-animation="animated fadeInDown">{i18n.t("Lorem1")} <br />{i18n.t("Mauris")}. </p> */}
										<div className="buttons">
											<a data-animation="animated fadeInLeft" href="about" className="thm-button hvr-bounce-to-right">{i18n.t("Learnmore")}</a>
											<a data-animation="animated fadeInRight" href="solution" className="thm-button inverse hvr-bounce-to-right2">{i18n.t("Viewservices")}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ backgroundImage: 'url("img/slide1.jpg")', backgroundPosition: 'center right', }} className="item slide-3">
						<div className="carousel-caption">
							<div className="thm-container">
								<div className="box valign-bottom">
									<div className="content text-center pull-right">
										<h2 data-animation="animated fadeInUp"><span>{i18n.t("Welcome")}</span> {i18n.t("toall")} <br /></h2>
										{/* <p data-animation="animated fadeInDown">{i18n.t("youwill")} </p> */}
										<div className="buttons">
											<a data-animation="animated fadeInLeft" href="about" className="thm-button hvr-bounce-to-right">{i18n.t("Learnmore")} </a>
											<a data-animation="animated fadeInRight" href="solution" className="thm-button inverse hvr-bounce-to-right2">{i18n.t("Viewservices")}</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a href="#minimal-bootstrap-carousel" role="button" data-slide="prev" className="left carousel-control"><i className="fa fa-angle-left"></i><span className="sr-only">Previous</span></a><a href="#minimal-bootstrap-carousel" role="button" data-slide="next" className="right carousel-control"><i className="fa fa-angle-right"></i><span className="sr-only">Next</span></a>
			</div>
			<section className="offer-wraper">
				<div className="container">
					<div className="row">
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/enerji.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("ENERJI"))}</h3>
								<p>{i18n.t("ENERJItext")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/anasayfa_maden.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("MADEN"))}</h3>
								<p>{i18n.t("MADENtext")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/Metalurji.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("Metalurji"))}</h3>
								<p>{i18n.t("Metalurjitext")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
					</div>
					<div className="row second">
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/muhendislik.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("muhendislikproje"))}</h3>
								<p>{i18n.t("MaterialScienceText")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/endustriyel.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("EndustrielTesisler"))}</h3>
								<p>{i18n.t("EndustrielTesislertext")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
						<div className="col-md-4 col-sm-4 col-xs-12">
							<div className="item">
								<figure className="img-box">
									<a href="#"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/hidrometalurji.png" alt="image" /> </a>
									<figcaption className="default-overlay-outer">
										<div className="inner">
											<div className="content-layer"> <Link className="this-link btn btn-primary" to={"solution"}>{i18n.t("SEEMORE")}</Link></div>
										</div>
									</figcaption>
								</figure>
								<h3>{firstCharUpper(i18n.t("hidrometalurji"))}</h3>
								<p>{i18n.t("hidrometalurjiText")}</p>
								<h6><Link to={"solution"}>{i18n.t("SEEMORE")}</Link></h6>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <section className="our-projects">
			<div className="container">
			    <div className="row head">
			        <div className="col-md-12">
			        <h2>Our Services</h2>
			        </div>
			    </div>
				<div className="row">
					<div className="col-md-12">
						<div id="services" className="owl-carousel owl-theme">

							<div className="item01" >
								<div className="image">
									<a className="box-offer" href=""> <img src="img/service1.jpg" alt="Nutrition Photo" /></a>
									<div className="overlay">
										<div className="box">
											<div className="content">
												<div className="top">
													<a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
												</div>
												<div className="bottom">
													<h3>Oil &amp; Petroleum</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="item1">
								<div className="image">
									<a className="box-offer" href=""><img src="img/service2.jpg" alt="Nutrition Photo" /></a>
									<div className="overlay">
										<div className="box">
											<div className="content">
												<div className="top">
													<a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
												</div>
												<div className="bottom">
													<h3>Oil &amp; Petroleum</h3>
												</div>
											</div>
										</div>
									</div>
								</div>								
							</div>

							<div className="item2">
								<div className="image">
									<a className="box-offer" href=""> <img src="img/service3.jpg" alt="Nutrition Photo" /></a>
									<div className="overlay">
										<div className="box">
											<div className="content">
												<div className="top">
													<a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
												</div>
												<div className="bottom">
													<h3>Oil &amp; Petroleum</h3>
												</div>
											</div>
										</div>
									</div>
								</div>								
							</div>
							<div className="item3">
								<div className="image">
								    <figure>
									<a className="box-offer" href=""> <img src="img/service4.jpg" alt="Nutrition Photo" /></a>
									<div className="overlay">
										<div className="box">
											<div className="content">
												<div className="top">
													<a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>
												</div>
												<div className="bottom">
													<h3>Oil &amp; Petroleum</h3>
												</div>
											</div>
										</div>
									</div>
									</figure>
								</div>								
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</section> */}
			<section className="quote">
				{/* <h3>{i18n.t("OurIndustry")}<br />
					{i18n.t("Itonlyrespect")}</h3> */}
			</section>
			<section className="about">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2>{i18n.t("ABOUTAS")}</h2>
							<p>{i18n.t("about1")} </p>
							<p>{i18n.t("about2")} </p>
							<p>{i18n.t("about3")} </p>
							{/* <ul>
								<li><i className="fa fa-caret-right" aria-hidden="true"></i>Vestibulum tincidunt neque id quam tristique pulvinar non in nulla. </li>
								<li><i className="fa fa-caret-right" aria-hidden="true"></i>Tincidunt neque id quam tristique pulvinar non in nulla. </li>
								<li><i className="fa fa-caret-right" aria-hidden="true"></i>Curabitur sem dolor, ultricies nec purus eu, convallis cursus leo. </li>
							</ul> */}
							<div className="button">
								<Link className="hvr-bounce-to-right btn" to={"about"}>{i18n.t("SEEMORE")}</Link>
							</div>
						</div>
						<div className="col-md-6">
							<div className="picture-wraper">

								<div className="first"><img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/hakkimizda.png" alt="image" /> </div>

							</div>
						</div>
					</div>
				</div>
			</section>
			
			{/* <SolutionContent /> */}

			{/* <section className="numbers">
				<div className="container">
					<div className="row statistic">
						<div className="col-md-4">
							<h2>We are proud
								<br />
								<span>with our achivness</span></h2>
						</div>
						<div className="col-md-8">
							<div className="box">
								<div className="number-box">
									<span className="counter">265</span>
								</div>
								<div className="text-box">
									<p>STAFFS ACROSS WORLDWIDE</p>
								</div>
							</div>
							<div className="box">
								<div className="number-box">
									<span className="counter">112</span>
								</div>
								<div className="text-box">
									<p>AWARDS WINNING</p>
								</div>
							</div>
							<div className="box">
								<div className="number-box">
									<span className="counter">359</span>
								</div>
								<div className="text-box">
									<p>SATISFIED CUSTUMERS</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* <section className="news">
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="our-news">
							<div className="title">
								<h2>Latest news</h2>
							</div>
							<div className="news1">
								<figure>
									<img src="img/new.jpg" alt="awsome photo"/>
								</figure>
								<div className="text-box">
									<div className="date">
										<p>
											14
											<br/>
											Apr
										</p>
									</div>
									<h3>Materials &amp; Manufacturing Service Engineers
									<br/>
									<span>by Martin - 25 Comments</span></h3>
									<p className="opis">
										Fusce non faucibus lorem. Cras eu velit id diam cursus
										tincidunt. Quisque quis augue placerat.
									</p>
									<a href="#">Read More</a>
								</div>
							</div>
							<div className="news1">
								<figure>
									<img src="img/new.jpg" alt="awsome photo"/>
								</figure>
								<div className="text-box">
									<div className="date">
										<p>
											14
											<br/>
											Apr
										</p>
									</div>
									<h3>Materials &amp; Manufacturing Service Engineers
									<br/>
									<span>by Martin - 25 Comments</span></h3>
									<p className="opis">
										Fusce non faucibus lorem. Cras eu velit id diam cursus
										tincidunt. Quisque quis augue placerat.
									</p>
									<a href="#">Read More</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="questions">
							<div className="title">
								<h2>Frequently Ask questions</h2>
							</div>
							<div id="accordion" role="tablist">
							<div className="toggle">
								<div className="toggle-heading" role="tab">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> <i className="fa fa-plus"></i>What is do i have to tell you a few lorem?<i className="fa fa-minus"></i></a>
								</div>
								<div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
									<div className="toggle-body">
										<p>
											Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
										</p>
									</div>

								</div>

							</div>
							<div className="toggle">
								<div className="toggle-heading" role="tab">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true" aria-controls="collapseOne"><i className="fa fa-plus"></i> What is do i have to tell you a few lorem?<i className="fa fa-minus"></i></a>
								</div>
								<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
									<div className="toggle-body">
										<p>
											Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
										</p>
									</div>

								</div>

							</div>
							<div className="toggle">
								<div className="toggle-heading" role="tab">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="true" aria-controls="collapseOne"> <i className="fa fa-plus"></i>What is do i have to tell you a few lorem?<i className="fa fa-minus"></i></a>
								</div>
								<div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
									<div className="toggle-body">
										<p>
											Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
										</p>
									</div>

								</div>

							</div>
							<div className="toggle">
								<div className="toggle-heading" role="tab">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="true" aria-controls="collapseOne"> <i className="fa fa-plus"></i>What is do i have to tell you a few lorem?<i className="fa fa-minus"></i></a>
								</div>
								<div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-expanded="false">
									<div className="toggle-body">
										<p>
											Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut labore. Love life’s sweetest reward Let it flow it floats back to you.
										</p>
									</div>

								</div>

							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}
			{/* <section className="our-team">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="team-text">
							<div className="title">
								<h2>Our Experts</h2>
							</div>
							<p>Lorem ipsum dolor sit amet, consectetur adi pisi cing elit, sed do eiusmod tempor exercitationemut. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipis civelit sed quia non qui dolorem or phone +11 987 654 3210 ipsum quia dolor sit amet, consectetur.</p>
							<a className="btn hvr-bounce-to-right" href="#">SEE MORE</a>
						</div>
					</div>
					<div className="col-md-8">
						<div className="members">
							<div className="member">
								<figure>
									<img src="img/member1.jpg" alt="awsome image"/>
									<div className="overlay">
										<div className="inner-box">
											<ul className="social">
												<li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
												<li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
												<li><a href="#"><i className="fa fa-skype" aria-hidden="true"></i></a></li>
											</ul>
										</div>
									</div>
								</figure>
								<div className="author-info">
									<h4>George Simons</h4>
									<p>Ceo &amp; Managing Director</p>
									<ul>
										<li>Tel: 800-700-6202</li>
										<li>Mail: simons@industrial.com</li>
									</ul>
								</div>
							</div>
							<div className="member">
								<figure>
									<img src="img/member2.jpg" alt="awsome image"/>
									<div className="overlay">
										<div className="inner-box">
											<ul className="social">
												<li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
												<li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
												<li><a href="#"><i className="fa fa-skype" aria-hidden="true"></i></a></li>
											</ul>
										</div>
									</div>
								</figure>
								<div className="author-info">
									<h4>Lisa Jones </h4>
									<p>Adviser &amp; Business Developer</p>
									<ul>
										<li>Tel: 800-700-6202</li>
										<li>Mail: simons@industrial.com</li>
									</ul>
								</div>
							</div>
							<div className="member">
								<figure>
									<img src="img/member3.jpg" alt="awsome image"/>
									<div className="overlay">
										<div className="inner-box">
											<ul className="social">
												<li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i> </a></li>
												<li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
												<li><a href="#"><i className="fa fa-skype" aria-hidden="true"></i></a></li>
											</ul>
										</div>
									</div>
								</figure>
								<div className="author-info">
									<h4>George Simons</h4>
									<p>Cheif Engineer</p>
									<ul>
										<li>Tel: 800-700-6202</li>
										<li>Mail: simons@industrial.com</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> */}
			{/* <section className="testimonials">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
					<div className="titles">
						<h2>Testimonials</h2>
					</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
							<blockquote>
								Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat, pulvinar dui aliquam, convallis sapien. Orci varius natoque penatibus et magnis dis parturient montes.
							</blockquote>
							<div className="testimonials-author">
								<div className="author-img">
									<img className="img-responsive" src="img/author.jpg" alt="img"/>
								</div>
								<h4>George Simons<span> - Lawyer</span></h4>
								<ul>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
								</ul>
							</div>
						</div>
						<div className="col-md-6">
							<blockquote>
								Fusce non faucibus lorem. Cras eu velit id diam cursus tincidunt in ut dui. Quisque quis augue placerat, pulvinar dui aliquam, convallis sapien. Orci varius natoque penatibus et magnis dis parturient montes.
							</blockquote>
							<div className="testimonials-author">
								<div className="author-img">
									<img className="img-responsive" src="img/author.jpg" alt="img"/>
								</div>
								<h4>George Simons<span> - Lawyer</span></h4>
								<ul>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
									<li><i className="fa fa-star" aria-hidden="true"></i></li>
								</ul>
							</div>
						</div>
						
				</div>
			</div>
		</section> */}
			<Contact />
			{/* <section className="partners">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Our partners</h2>
						</div>
						<div className="col-md-12">
							<div id="about-us-our-partners" className="owl-carousel owl-theme">
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/partner-logos/logo1.png" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/partner-logos/logo2.png" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/partner-logos/logo3.png" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/partner-logos/logo4.png" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/partner-logos/logo5.png" alt="Nutrition Photo" />
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}


		</>
	);
}

const HomePageComponent = observer(HomePage);
export default HomePageComponent;

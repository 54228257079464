import { useEffect } from "react";
import i18n from "../common/languageConf";
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from "mobx-react";
import SolutionContent from '../components/solutionContent'

function AboutPage() {
	useEffect(() => {
		window.scrollTo(0, 0);

	}, [GlobalSettingStore.GlobalSetting])
	return (
		<>

			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>{i18n.t("ABOUTAS")}</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>
							<li>{i18n.t("Home")}</li>
							<li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
							<li><span>{i18n.t("ABOUTAS")}</span></li>
						</ul>
					</div>
				</div>
			</section>

			<section className="overview">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="big-image">
								<img style={{ width: "100%", height: "100%", objectFit: "cover" }} src="img/hakkimizda2.jpg" alt="image" />
							</div>
							<div className="small-images">
								{/* <div className="one"><img src="img/about-small.jpg" alt="awsome image"/></div>	
							 <div className="two"><img src="img/about-small.jpg" alt="awsome image"/></div>	 */}
							</div>
						</div>
						<div className="col-md-6">
							<div className="title">
								<h2>{i18n.t("Companyoverview")}</h2>
							</div>
							<p>{i18n.t("about1")} </p>

							<p>{i18n.t("about2")} </p>

							<p>{i18n.t("about3")} </p>

							{/* <ul>
							<li><i className="fa fa-caret-right" aria-hidden="true"></i>Cras eu velit id diam cursus tincidunt in ut dui. </li>
							<li><i className="fa fa-caret-right" aria-hidden="true"></i>Quisque quis augue placerat, pulvinar dui aliquam, convallis sapien.  </li>
							<li><i className="fa fa-caret-right" aria-hidden="true"></i>Orci varius natoque penatibus et magnis dis </li>
							<li> <i className="fa fa-caret-right" aria-hidden="true"></i>  Parturient montes, nascetur ridiculus mus. Nulla feugiat cursus egestas.</li>
						</ul> */}

						</div>
					</div>
				</div>
			</section>
			<section className="adventages">
				<div className="container">
				{/* <SolutionContent /> */}

				</div>
			</section>
			{/* <section className="our-team">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="title">
								<h2>Our Team</h2>
							</div>

						</div>
						<div className="members">
							<div className="col-md-3">
								<div className="member">
									<figure>
										<img src="img/member1.jpg" alt="awsome image" />
									</figure>
									<div className="author-info">
										<h4>George Simons</h4>
										<p>
											Ceo &amp; Managing Director
										</p>
										<ul>
											<li>
												Tel: 800-700-6202
											</li>
											<li>
												Mail: simons@industrial.com
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="member">
									<figure>
										<img src="img/member2.jpg" alt="awsome image" />
									</figure>
									<div className="author-info">
										<h4>Lisa Jones </h4>
										<p>Adviser &amp; Business Developer</p>
										<ul>
											<li>Tel: 800-700-6202</li>
											<li>Mail: simons@industrial.com</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="member">
									<figure>
										<img src="img/member3.jpg" alt="awsome image" />
									</figure>
									<div className="author-info">
										<h4>George Simons</h4>
										<p>Cheif Engineer</p>
										<ul>
											<li>Tel: 800-700-6202</li>
											<li>Mail: simons@industrial.com</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="member">
									<figure>
										<img src="img/member4.jpg" alt="awsome image" />
									</figure>
									<div className="author-info">
										<h4>George Simons</h4>
										<p>Cheif Engineer</p>
										<ul>
											<li>Tel: 800-700-6202</li>
											<li>Mail: simons@industrial.com</li>
										</ul>
									</div>
								</div>
							</div>

						</div>

					</div>

				</div>
			</section> */}
			{/* <section className="achivment">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="title">
								<h2>Our Achivment</h2>
							</div>
						</div>
						<div className="col-md-12">
							<div id="about-us-our-partners" className="owl-carousel owl-theme">
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/certificate1.jpg" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/certificate2.jpg" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/certificate3.jpg" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/certificate4.jpg" alt="Nutrition Photo" />
									</div>

								</div>
								<div className="item-partner">
									<div className="image">
										<img className="img-responsive" src="img/certificate3.jpg" alt="Nutrition Photo" />
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

		</>
	);
}

const AboutPageComponent = observer(AboutPage);
export default AboutPageComponent;

import { useEffect, useState } from "react";
import i18n from "../common/languageConf";
import { useLanguage } from '../common/LanguageContext';
import GlobalSettingStore from "../Stores/GlobalSettingStore";
import { observer } from 'mobx-react-lite';
import counterStore from "../Stores/counterStore";
import { Link, useLocation, useNavigate } from "react-router-dom";




function HomeHeader() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const location = useLocation();
	let navigate = useNavigate();



	const toggleMenu = () => {
		console.log("toggle")
		setIsMenuOpen(true);
	};
	const toggleMenuLeave = () => {
		const delayedExecution = setTimeout(() => {
			setIsMenuOpen(false);
			console.log("toggle toggleMenuLeave")

		}, 3000);
		return () => clearTimeout(delayedExecution);
	};

	useEffect(() => {

		const pathname = new URL(window.location.href).pathname;
		const pathParts = pathname.split("/");
		const openValue = pathParts[pathParts.length - 1];
	
		console.log("HomeHeader URLValue > " + openValue); 

		if (openValue=="index.html") {
			console.log("HomeHeader URLValue yakalandı > " + openValue  ); 
			navigate("/")

		}

	}, [GlobalSettingStore.GlobalSetting])

	function changeLang(lang: string) {
		console.log("change " + lang)
		GlobalSettingStore.setGlobalSetting(lang)
		console.log("GlobalSettingStore.getGlobalSetting()" + GlobalSettingStore.getGlobalSetting());
		console.log("GlobalSettingStore.getGlobalSetting()" + counterStore.count)
		i18n.locale = GlobalSettingStore.getGlobalSetting()

	}
	return (
		<header className="main-header">
			<div className="container">
				<div className="row">
					<div className="col-md-3">
						<div className="logo">
							<Link to="/"><img src="../img/logo_trial.png" alt="logo" /></Link>
						</div>
					</div>
					<div className="col-md-6">
						<div className="general-info">
							<ul>
								<li>
									<div className="icon">
										{/* <i className="fa fa-map-marker" aria-hidden="true"></i> */}
									</div>
									<div className="text">
										{/* <p>
											<span className="add">{i18n.t("ADDRESS")}</span>
											<br />
											<span>20c Factory Street,<br />
												Newyork, NY 10205</span>
										</p> */}
									</div>
								</li>
								<li>
									<div className="icon">
										{/* <i className="fa fa-phone" aria-hidden="true"></i> */}
									</div>
									<div className="text">
										{/* <p>
											<span className="add">{i18n.t("ADDRESS")}</span>
											<br />
											<span>20c Factory Street,<br />
												Newyork, NY 10205</span>
										</p> */}
									</div>
								</li>

							</ul>
						</div>

					</div>
					<div className="col-md-3">
						<div className="social-icons">
							<ul>


								<li>
									<span onClick={() => { changeLang("tr") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"tr"}`} />
									{/* <br />
									<p>TR</p> */}
								</li>
								<li>
									<span onClick={() => { changeLang("en") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"gb"}`} />
									{/* <br />
									<p style={{justifyContent:"flex-end"}}>EN</p> */}
								</li>
								<li>
									<span onClick={() => { changeLang("cn") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"cn"}`} />
									{/* <br />
									<p>CN</p> */}
								</li>
								{/* <li>
									<ul onMouseEnter={toggleMenu} onMouseLeave={toggleMenuLeave}>
										<img width={50} height={50} style={{ marginLeft: -7 }} src="../img/lang_logo.png"></img>
									</ul>
								</li> */}

							</ul>
							{/* {isMenuOpen && (
								<><ul style={{ marginLeft: 50 }} onMouseEnter={toggleMenu} onMouseLeave={toggleMenuLeave}>
									<span onClick={() => { changeLang("tr") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"tr"}`} />
									<span onClick={() => { changeLang("en") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"gb"}`} />
									<span onClick={() => { changeLang("cn") }} style={{ width: 32, height: 32, marginRight: 20, cursor: "pointer" }} className={`flag-icon flag-icon-${"cn"}`} />
								</ul>
								</>
							)} */}
						</div>
					</div>
				</div>
			</div>

		</header>
	);
}
const ObserverBasketListComponent = observer(HomeHeader);
export default ObserverBasketListComponent;

import { useEffect } from 'react';
import i18n from '../common/languageConf';
import '../css/style.css';
import { useLanguage } from '../common/LanguageContext';
import { observer } from "mobx-react";
import GlobalSettingStore from '../Stores/GlobalSettingStore';
import { Link } from 'react-router-dom';


function Footer() {
	const { language, changeLanguage } = useLanguage();

	useEffect(() => {
		changeLanguage("tr")
	}, [GlobalSettingStore.GlobalSetting])
	return (
		<>
			<section className="quote-request">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<h4>{i18n.t("Lookingfor")}</h4>
						</div>
						<div className="col-md-3">
							<a className="btn hvr-bounce-to-right" href="/contact">{i18n.t("REQUESTAQUOTE")}</a>
						</div>
					</div>
				</div>
			</section>
			<footer className="main-footer">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<figure>
								<img className="img-responsive" src="img/footerlogo4.png" alt="awsome image" />
							</figure>
							<p>{i18n.t("footerLogobuttom")}  </p>
							<div className="social-icons">
								<ul>
									<li>
										<a href="" ><i className="fa fa-facebook" aria-hidden="true"></i></a>
									</li>
									<li>
										<a href="" ><i className="fa fa-vimeo" aria-hidden="true"></i></a>
									</li>

									<li>
										<a href="" ><i className="fa fa-linkedin" aria-hidden="true"></i></a>
									</li>
									<li>
										<a href="" ><i className="fa fa-twitter" aria-hidden="true"></i></a>
									</li>

								</ul>
							</div>
						</div>
						<div className="col-md-4 sec-tab">
							<h2>{i18n.t("OurSolutions")}</h2>
							<ul className="solutions">
								<li><Link to="solution">{i18n.t("Enerji")}</Link></li>
								<li><Link to="solution">{i18n.t("Maden")}</Link></li>
								<li><Link to="solution">{i18n.t("Metallueloji")}</Link></li>
								<li><Link to="solution">{i18n.t("HavaAyristirma")}</Link></li>
								<li><Link to="solution">{i18n.t("Sulfurukasit")}</Link></li>
								<li><Link to="solution">{i18n.t("Muhendislik")}</Link></li>
								<li><Link to="solution">{i18n.t("ProjeYonetimi")}</Link></li>
								<li><Link to="solution">{i18n.t("Gubre")}</Link></li>
								<li><Link to="solution">{i18n.t("GazTemizleme")}</Link></li>
								<li><Link to="solution">{i18n.t("AltinLeach")}</Link></li>
								<li><Link to="solution">{i18n.t("Industrialplant")}</Link></li>
							</ul>
						</div>
						{/* <div className="col-md-3">
							<h2>{i18n.t("Latestnews")}</h2>
							<div className="footer-news">
								<p>
									{i18n.t("haberler1")}
									<br />
									<span>July 12, 2023</span>
								</p>
								<p>
									{i18n.t("haberler1")}
									<br />
									<span>July 12, 2018</span>
								</p>
								<p>
									{i18n.t("haberler1")}
									<br />
									<span>July 12, 2016</span>
								</p>
							</div>
						</div> */}
						<div className="col-md-4">
							<h2>{i18n.t("GetinTouch")}</h2>
							<ul className="contact-info">
								<li>
									<a style={{textDecoration:"none", color:"white"}} href="https://maps.app.goo.gl/xQBtbGuCvbDGyRPa9" target='blank'>
										<i className="fa fa-map-marker" aria-hidden="true"></i><div className="address"> {i18n.t("adresmah")} <br /> {i18n.t("adresofis")}<br />{i18n.t("adresilce")}<br /> {i18n.t("adressehir")}</div>
									</a>
								</li>
								<li><i className="fa fa-phone" aria-hidden="true"></i><div className="address">(+90) 533 690 61 54  <br /> </div></li>
								<li><i className="fa fa-clock-o" aria-hidden="true"></i><div className="address">Mon - Sat: 09.00am to 18.00pm </div></li>
								<li><i className="fa fa-envelope" aria-hidden="true"></i><div className="address"><a href="#">info@epcproje.com  </a></div></li>

							</ul>
						</div>
					</div>
				</div>
			</footer>
			<section className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="pull-left">
							<p>Copyright  EPC 2023. All rights reserved</p>
						</div>
						<div className="pull-right">
							<p>Created by: EPC Technology</p>
						</div>
					</div>
				</div>
			</section>
			<a className="scroll-top" href="#"><i className="fa fa-angle-up"></i> </a>
		</>
	);
}
const FooterPageComponent = observer(Footer);
export default FooterPageComponent;

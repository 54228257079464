import { useEffect, useState } from "react";
import i18n from "../../common/languageConf";
import GlobalSettingStore from "../../Stores/GlobalSettingStore";
import { observer } from "mobx-react";

function SolutionEngineering() {

	useEffect(() => {

	}, [GlobalSettingStore.GlobalSetting])

	
	return (
		<>
				<div className="container">
					<div className="row">
						
						<div className="col-md-9">
							<div className="img-box">
								<div className="first"><img style={{ width: "100%", height: "100%",  }} src="img/tamamlananprojeler/Resim28.png" alt="image" /> </div>
								<div className="second"><img style={{ width: "100%", height: "100%", }} src="img/tamamlananprojeler/Resim29.png" alt="image" /> </div>
							</div>
							<div className="text" style={{ whiteSpace: 'pre-line' }}>
								{i18n.translate("muhendisliktext")}
							</div>
						
							<div className="questions-sol">
								
							</div>
						</div>
					</div>
				</div>
		</>
	);
}

const SolutionPageComponent = observer(SolutionEngineering);
export default SolutionPageComponent;
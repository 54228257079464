import React from 'react';
import { createContext, useContext, ReactNode } from 'react';

type LanguageContextType = {
  language: string;
  changeLanguage: (newLanguage: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

type LanguageProviderProps = {
  children: ReactNode;
};

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = React.useState<string>('en');

  const changeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const value: LanguageContextType = { language, changeLanguage };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
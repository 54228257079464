import i18n from "../common/languageConf";

function NewsPage() {
	return (
		<>


			<section className="header-title">
				<div className="container">
					<div className="row">
						<div className="title">
							<h2>News</h2>
						</div>
					</div>
				</div>
			</section>
			<section className="page-nav">
				<div className="container">
					<div className="row">
						<ul>


							<li>{i18n.t("Home")}</li>
							<li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
							<li><span>News</span></li>
						</ul>
					</div>
				</div>
			</section>
			<section className="news-section">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="news-wraper">
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb1.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>How Advanced Manufactoring is Transforming the FACTORY</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb2.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>Solar Power becoming cheap enough to compete with Fuels</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb3.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>How Advanced Manufactoring is Transforming the FACTORY</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb4.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>Solar Power becoming cheap enough to compete with Fuels</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb5.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>How Advanced Manufactoring is Transforming the FACTORY</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="news-box">
										<div className="img-box">
											<img src="img/news-thumb6.jpg" alt="img" />
										</div>
										<div className="text-box">
											<div className="header">
												<h4>Solar Power becoming cheap enough to compete with Fuels</h4>
												<p>
													Nov 14, 2016
												</p>
											</div>
											<div className="text">
												<p>
													Etiam porta, leo sit amet faucibus consectetur, nunc tortor facilisis elit, quis viverra turpis ligula eget purus. Mauris tempor congue ligula et euismod. gna.
												</p>
												<a href="#">Read More</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<aside className="blog-info">
								<div className="search-box">
									<form>
										<div className="form-group">

											<input className="form-control" placeholder="Search..." type="search" />
											<button type="submit">
												<i className="fa fa-search" aria-hidden="true"></i>
											</button>

										</div>
									</form>
								</div>
								<div className="category-widget">
									<div className="title">
										<h4>Categories</h4>
									</div>
									<ul>
										<li>
											<a href="">Manufactoring</a>
										</li>
										<li>
											<a href="">Chemical</a>
										</li>
										<li>
											<a href="">Construction</a>
										</li>
										<li>
											<a href="">Energy</a>
										</li>
										<li>
											<a href="">Materials</a>
										</li>
									</ul>
								</div>
								<div className="news-widget">
									<div className="title">
										<h4>Popular Post</h4>
									</div>
									<div className="news-item">
										<figure>
											<img src="img/blog-thumbnail.jpg" alt="" />
										</figure>
										<div className="text-box">
											<h5><a href=""> How Advanced Manufactoring is Transforming the FACTORY</a></h5>
											<p>
												October 21, 2016
											</p>
										</div>
									</div>
									<div className="news-item">
										<figure>
											<img src="img/blog-thumbnail2.jpg" alt="" />
										</figure>
										<div className="text-box">
											<h5><a href=""> How Advanced Manufactoring is Transforming the FACTORY </a></h5>
											<p>
												October 21, 2016
											</p>
										</div>
									</div>
									<div className="news-item">
										<figure>
											<img src="img/blog-thumbnail3.jpg" alt="" />
										</figure>
										<div className="text-box">
											<h5><a href=""> How Advanced Manufactoring is Transforming the FACTORY </a></h5>
											<p>
												October 21, 2016
											</p>
										</div>
									</div>
								</div>
								<div className="instigram-widget">
									<div className="title">
										<h4>Text Widget</h4>
									</div>
									<p>
										Morbi tincidunt iaculis ipsum eget laoreet. Cras at finibus ante, posuere ornare ex. Mauris sollicitudin ornare ullamcorper. Donec in arcu purus. Phasellus ullamcorper.
									</p>

								</div>
								<div className="tags-widget">
									<div className="title">
										<h4>Popular Tags</h4>
									</div>
									<ul>
										<li>
											<a href=""> Future</a>
										</li>
										<li>
											<a href="">Science</a>
										</li>
										<li>
											<a href="">Factory</a>
										</li>
										<li>
											<a href=""> Industrial</a>
										</li>
										<li>
											<a href="">Construction</a>
										</li>
										<li>
											<a href="">Glass</a>
										</li>
										<li>
											<a href="">Helmet</a>
										</li>
										<li>
											<a href="">Wind Energy</a>
										</li>
										<li>
											<a href="">Gloves</a>
										</li>
									</ul>

								</div>
							</aside>
						</div>
					</div>
				</div>
			</section>


		</>
	);
}

export default NewsPage;
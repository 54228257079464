import { observable, action, makeObservable, makeAutoObservable } from "mobx";

class GlobalSettingStore {
   GlobalSetting:string = "tr" ;


  constructor() {
    makeAutoObservable(this);
  }

   getGlobalSetting() {
    return this.GlobalSetting;
  }

  setGlobalSetting(GlobalSetting: string){
    this.GlobalSetting = GlobalSetting;
  }

 
}

export default new GlobalSettingStore();